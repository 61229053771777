import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import PostLayout from "../components/post-layout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = PostLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello to my awesome content in MDX`}</p>
    <p>{`great for sure :)`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn1.azybao.training/wp-content/uploads/2019/12/13133815/delegates-networking-during-conference-lunch-PTQSYZV-4site.jpg",
        "alt": "alt"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      